(function ( $ ) {
     $.fn.projectMain = function (options) {
        var scrollTop = 0;
        var objBody   = $(this);

        function _init() {
            _initMenu();
            _initCloseContact();
            _initFormFocuses();
            _initPhotogalery();
            _initShowMore();
        }

        function _initMenu(){
            $("#head .mobileNav").click(function(){
                if($('#head ul').hasClass('active')){
                    $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');

                    $('#head ul').removeClass('active');
                    $('body').removeClass('locked');
                }else{
                    $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');

                    $('#head ul').addClass('active');
                    $('body').addClass('locked');
                }
            });

            $("#contactBtn").click(function(){
                $('#contacts').addClass('active loading loaded');
            })
        }

        function _initCloseContact(){
            $('#contacts a.close').click(function(){
                $(objBody).removeClass('locked');
                $('html, body').animate({
                      scrollTop: scrollTop
                }, 0);
                $('#contacts').removeClass('active loading loaded');
            });
        }

        function _initShowMore(){
            $('#product-detail .text .more').click(function(){
                $(this).addClass('hide');
               $('#product-detail .text ul').addClass('active');
            });
        }

        function _initPhotogalery(){
            var owlPhotos = $('.owl-photogalery');
            owlPhotos.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1
                    },
                    768 : {
                        items: 2
                    },
                    880 : {
                        items: 3
                    },
                    1100 : {
                        items: 4
                    }
                },
                autoplay:false
            });

            $('.head .img').magnificPopup({
                type: 'image',
                delegate: 'a',
                gallery: {
                    enabled: true
                }
            });
        }

        function _initFormFocuses(){
            forms = $(objBody).find('#product-detail');
            forms = $(forms).find('.form');
            $(forms).find('input[type="text"],textarea').each(function( index ) {
                $(this).on("focus", function() {
                    $(this).closest('li').addClass('focus filled');
                }).on("blur", function() {
                    $(this).closest('li').removeClass('focus');
                    val = $(this).val();
                    if(val == ''){
                        $(this).closest('li').removeClass('filled');
                    }
                });
            });
        }

        _init();
       return {
         reload:_init,
       };
    };
}( jQuery ));

$("body").projectMain();
